import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { SessionService } from 'src/utilities/session/session.service';



@Injectable()
export class LoggedOutGuard implements CanActivate {
  constructor(private sessionService: SessionService, private router: Router) { }

  canActivate(): boolean {
    if (!this.sessionService.isLoggedIn()) {
      return true;
    } else {
      console.log('logged out guard redirecting to home');
      void this.router.navigate(['/index']);
      return false;
    }
  }
}
