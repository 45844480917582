import { Title } from '@angular/platform-browser';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATION, RouterNavigationAction } from '@ngrx/router-store';
import { Injectable } from '@angular/core';

import { tap } from 'rxjs/operators';
import { RouterStateTitle } from 'src/utilities/router-state-serializer';



@Injectable()
export class TitleUpdaterEffects {
  @Effect({ dispatch: false })
  updateTitle$ = this.actions$.pipe(
    ofType(ROUTER_NAVIGATION),
    tap((action: RouterNavigationAction<RouterStateTitle>) => {
      this.titleService.setTitle('PicTrack');
    })
  );

  constructor(private actions$: Actions, private titleService: Title) {}
}
