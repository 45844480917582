import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/loader.service';
import { SessionService } from 'src/utilities/session/session.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {

  constructor(public sessionService: SessionService, private router: Router, public loading: LoaderService) { }

  ngOnInit(): void {
  }

}
