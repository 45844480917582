import { Inject, Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError, mergeMap } from 'rxjs/operators';

import { SessionService } from '../session/session.service';
import { Router, ActivatedRoute } from '@angular/router';
import { EnvironmentConfig } from '../interfaces/environment.interface';
import { APP_ENVIRONMENT } from './environment.injection-token';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
  public loginRoute = '/account/login';  
  public forbiddenRoute = '/forbidden';

  constructor(
    private session: SessionService,
    private router: Router,
    @Inject(APP_ENVIRONMENT) private environment: EnvironmentConfig
    ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return next.handle(req).pipe(
      // tap(event => console.log('HTTP EVENT', JSON.stringify(event))),
      catchError(event => {
        if (event instanceof HttpErrorResponse && event.status === 401) {
          if (this.session.user) {
            console.log('401 found, logging out');
            this.session.logout();
          } else {
            console.log('401 found, no active session, logging out');
            void this.router.navigate([this.loginRoute]);
          }
        }
        return throwError(event);
      }),
      catchError(event => {
        if (event instanceof HttpErrorResponse && event.status === 403) {
          if (this.session.user) {
            console.log('403 found, Not access');
            void this.router.navigate([this.forbiddenRoute]);            
          } else {
            this.session.logout();
          }
        }
        return throwError(event);
      }),     
      map((res: any) => {        
        if (res instanceof HttpResponse && !!res.url && res.url.indexOf(this.environment.api) >= 0 && res.body.data) {
          res = res.clone({ body: res.body.data });
        }
        return res;
      })
    );
  }
}
