import { Injectable } from "@angular/core";
import { UserRole } from "../interfaces/user.api.interface";
import { SessionService } from "../session/session.service";
import { Modules, Permissions } from "../interfaces/permissions.api.interface";

@Injectable({ providedIn: 'root' })
export class MenuConfig {


  //Third Submenu Locations
  configurationLocalitiesModules: IMenuItem[] = [
    { name: 'configurationLocalitiesCountries', routerLink: ['/countries'], icon: '', childItems: [], permissions: [{module: Modules.Countries, permission: Permissions.List }] },
    { name: 'configurationLocalitiesRegions', routerLink: ['/regions'], icon: '', childItems: [] , permissions: [{module: Modules.Regions, permission: Permissions.List}]  },
    { name: 'configurationLocalitiesCities', routerLink: ['/cities'], icon: '', childItems: [] , permissions: [{module: Modules.Cities, permission: Permissions.List}]  }
  ]

  //Submenu configuración
  configurationModules: IMenuItem[] = [
    { name: 'configurationUsers', routerLink: ['/users'], icon: '', childItems: [] , permissions: [{module: Modules.Users, permission: Permissions.List}]  },
    { name: 'configurationRoles', routerLink: ['/roles'], icon: '', childItems: [] , permissions: [{module: Modules.Roles, permission: Permissions.List}] },
    { name: 'configurationClients', routerLink: ['/clients'], icon: '', childItems: [] , permissions: [{module: Modules.Clients, permission: Permissions.List}] },
    { name: 'configurationSuppliers', routerLink: ['/suppliers'], icon: '', childItems: [] , permissions: [{module: Modules.Suppliers, permission: Permissions.List}] },
    { name: 'configurationLocations', routerLink: ['/locations/list'], icon: '', childItems: [] , permissions: [{module: Modules.Locations, permission: Permissions.List}] },
    { name: 'configurationLocationsStorages', routerLink: ['/locations/listCompanies'], icon: '', childItems: [] , permissions: [{module: Modules.Locations, permission: Permissions.ListStorages}] },
    { name: 'configurationLocalities', routerLink: ['/countries'], icon: '', childItems: this.configurationLocalitiesModules ,  permissions: [{module: Modules.Countries, permission: Permissions.List}, {module: Modules.Regions, permission: Permissions.List}, {module: Modules.Cities, permission: Permissions.List}]  },
    { name: 'configurationSettingValues', routerLink: ['/settingValues'], icon: '', childItems: [] ,  permissions: [{module: Modules.SettingsValues, permission: Permissions.List}]  },
    { name: 'configurationProducts', routerLink: ['/products'], icon: '', childItems: [] , permissions: [{module: Modules.Products, permission: Permissions.List}]  },
  ]

   //Submenu production
   productionModules: IMenuItem[] = [
    { name: 'productionBatchesClassification', routerLink: ['/productionBatches/listClassification'], icon: '', childItems: [] , permissions: [{module: Modules.ProductionClassification, permission: Permissions.List}]  },
    { name: 'productionBatchesProduction', routerLink: ['/productionBatches/listProduction'], icon: '', childItems: [] , permissions: [{module: Modules.ProductionProduction, permission: Permissions.List}] },
    { name: 'productionBatchesBatches', routerLink: ['/productionBatches/list'], icon: '', childItems: [] , permissions: [{module: Modules.ProductionBatches, permission: Permissions.List}] },
    { name: 'productionBatchesPackagings', routerLink: ['/productionBatches/listPackagings'], icon: '', childItems: [] , permissions: [{module: Modules.ProductionBatchesPackagings, permission: Permissions.List}] },
  ]

  //Submenu statistics
  statisticsModules: IMenuItem[] = [
    { name: 'statisticsProducts', routerLink: ['/statistics/listProducts'], icon: '', childItems: [] , permissions: [{module: Modules.StatisticsProducts, permission: Permissions.List}]  },
    { name: 'statisticsGeoZones', routerLink: ['/statistics/listGeozones'], icon: '', childItems: [] , permissions: [{module: Modules.StatisticsGeoZones, permission: Permissions.List}] },
    { name: 'statisticsStoragesEntry', routerLink: ['/statistics/listStoragesEntry'], icon: '', childItems: [] , permissions: [{module: Modules.StatisticsStoragesEntry, permission: Permissions.List}] },
  ]

  /*Array menu principal */
  menuModules: IMenuItem[] = [
    { name: 'home', routerLink: ['/index'], icon: 'icon_home.png', iconStyle:'menu-icon', childItems: [], permissions: []},
    { name: 'reception', routerLink: ['/receptionBatches'], icon: 'icon_reception.png', iconStyle:'menu-icon-2', childItems: [] , permissions: [{module: Modules.Reception, permission: Permissions.List}, {module: Modules.Reception, permission: Permissions.Add}, {module: Modules.Reception, permission: Permissions.Edit}, {module: Modules.Reception, permission: Permissions.PrintLabel}, {module: Modules.Reception, permission: Permissions.StatusHistory}]},
    { name: 'intro', routerLink: null, icon: 'icon_intro.png', iconStyle:'menu-icon', childItems: this.productionModules , permissions: [
      {module: Modules.ProductionClassification, permission: Permissions.List}, {module: Modules.ProductionProduction, permission: Permissions.List}, {module: Modules.ProductionBatches, permission: Permissions.List}
    ]},
    { name: 'load', routerLink: ['/loads'], icon: 'icon_load.png', iconStyle:'menu-icon', childItems: [] , permissions: [{module: Modules.Loads, permission: Permissions.List}]},
    { name: 'geoloc', routerLink: ['/geoloc'], icon: 'icon_geo.png', iconStyle:'menu-icon', childItems: [], permissions: [{module: Modules.Geoloc, permission: Permissions.Add}]},
    { name: 'statistics', routerLink: null, icon: 'icon_statistics.png', iconStyle:'menu-icon', childItems: this.statisticsModules , permissions: [
      {module: Modules.StatisticsProducts, permission: Permissions.List}, {module: Modules.StatisticsGeoZones, permission: Permissions.List}, {module: Modules.StatisticsStoragesEntry, permission: Permissions.List}
    ]},
    { name: 'configuration', routerLink: null, icon: 'icon_config.png', iconStyle:'menu-icon', childItems: this.configurationModules , permissions: [
      {module: Modules.Users, permission: Permissions.List}, {module: Modules.Countries, permission: Permissions.List}, {module: Modules.Regions, permission: Permissions.List}, 
      {module: Modules.Cities, permission: Permissions.List}, {module: Modules.SettingsValues, permission: Permissions.List}, {module: Modules.Roles, permission: Permissions.List}, 
      {module: Modules.Locations, permission: Permissions.List}, {module: Modules.Clients, permission: Permissions.List}, {module: Modules.Suppliers, permission: Permissions.List}, 
      {module: Modules.Locations, permission: Permissions.ListStorages}, 
    ]}
  ];

  /**
   * Devuelve los modulos de la aplicación
   */
  private GetModules(): IMenuItem[] {
    return this.menuModules;
  }

  /**
   * Devuelve las opciones de menu disponibles para un usuario
   */
  public GetMenuOptions(sessionService: SessionService): IMenuItem[] {
    var options = this.GetModules();
    const isAdmin = sessionService.isAdmin();

    if (sessionService.isLoggedIn()) {
      var userRoles = sessionService.user.ListPermissions;
      options = options.filter(item => {
        return (isAdmin || item.permissions.length == 0 || userRoles.find(o => item.permissions.find(x => x.module == o.module && o.permissions.includes(x.permission)))) && ((!item.session) || (item.session === 'with'));
      });
    } else { 
      options = options.filter(item => (!item.session || item.session === 'without'));
    }

    return options;
  }

  /**
   * Devuelve las opciones de un submenu disponibles para un usuario
   */
  public GetSubMenuOptions(sessionService: SessionService, menuSelected: IMenuItem): IMenuItem[] {
    var options = menuSelected.childItems;
    const isAdmin = sessionService.isAdmin();

    if (sessionService.isLoggedIn()) {
      var userRoles = sessionService.user.ListPermissions;
      options = options.filter(item => {
        return (isAdmin || item.permissions.length == 0 || userRoles.find(o => item.permissions.find(x => x.module == o.module && o.permissions.includes(x.permission)))) && ((!item.session) || (item.session === 'with'));
      });
    } else { 
      options = options.filter(item => (!item.session || item.session === 'without'));
    }

    options.forEach(item => {
      if (item.childItems != null && item.childItems.length > 0){
        item.childItems = item.childItems.filter(subItem => {
          return (isAdmin || item.permissions.length == 0 || userRoles.find(o => subItem.permissions.find(x => x.module == o.module && o.permissions.includes(x.permission)))) && ((!subItem.session) || (subItem.session === 'with'));
        });
      }
    });

    return options;
  }

  /**
   * Devuelve las opciones de menu disponibles para un usuario logeado
   */
  public GetFirstMenuOptions(sessionService: SessionService): IMenuItem {
    var options = this.GetModules();
    const isAdmin = sessionService.isAdmin();
    var userRoles = sessionService.user.ListPermissions;

    options = options.filter(item => {
      return (isAdmin || item.permissions.length == 0 || userRoles.find(o => item.permissions.find(x => x.module == o.module && o.permissions.includes(x.permission)))) && ((!item.session) || (item.session === 'with'));
    });

    var firstOption = options[0];
    
    //Si tiene elementos de segundo nivel, buscamos la primera opción en el nivel
    if (firstOption != null && firstOption.childItems != null && firstOption.childItems.length > 0){
      var secondLevelOptions = firstOption.childItems.filter(item => {
        return ((isAdmin) || userRoles.find(o => item.permissions.find(x => x.module == o.module && o.permissions.includes(x.permission)))) && ((!item.session) || (item.session === 'with') );
      });
      firstOption = secondLevelOptions != null && secondLevelOptions.length > 0 ? secondLevelOptions[0] : firstOption;
    }

    return firstOption;
  }


  public hasChildItems(menuItem: IMenuItem)
  {
    return menuItem.childItems != null && menuItem.childItems.length > 0
  }

  public GetLiClass(menuItem: IMenuItem) : string
  {
    return this.hasChildItems(menuItem) ? "dropdown-menu dropdown-menu-default dropdown-user-responsive" : "";
  }

  public GetLiAttr(menuItem: IMenuItem) : string
  {
    return this.hasChildItems(menuItem) ? "dropdown-menu dropdown-menu-default dropdown-user-responsive" : "";
  }


}

export interface IMenuItem {
  name: string;
  shortName?: string;
  routerLink: any[];
  session?: 'with' | 'without';
  icon: string;
  iconStyle?: string;
  childItems: IMenuItem[];
  permissions: IMenuPermission[];
}

export interface IMenuPermission {
  module: number;
  permission: number;
}
