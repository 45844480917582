import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionService } from 'src/utilities/session/session.service';



@Injectable()
export class LoggedInGuard implements CanActivate, CanActivateChild {
  
  constructor(private sessionService: SessionService, private router: Router) { }
  
  async canActivate(): Promise<boolean> {
    if (!!this.sessionService.isLoggedIn()) {
      //Recargamos el listado de permisos del usuario en sesion
      await this.sessionService.LoadPermissions();
      return true;
    } else {
      console.log('logged in guard redirecting to login');      
      this.sessionService.logout();
      void this.router.navigate(['/account/login']);
      return false;
    }
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.canActivate();
  }

}
