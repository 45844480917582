import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { DialogMessageComponent } from './dialog-message/dialog-message.component';
import { IDialogMessage } from './dialog-message/dialog.message.interface';
import { DialogYesNoComponent } from './dialog-yes-no/dialog-yes-no.component';
import { Observable } from 'rxjs';

@Injectable()
export class DialogService {

  constructor(public dialog: MatDialog) { }

  /**
   * Muestra un popup de notificación de un mensaje
   * @param message  Mensaje
   * @param title Titulo Opcional
   * @param minWidth Ancho mínimo
   */
  showMessage(message: string, title: string = null, minWidth: number = 400): Observable<any> {
    const dialogConfig = new MatDialogConfig();

    var dialogData : IDialogMessage = {
      title : title,
      message : message
    } 

    //Configuracion del dialog
    dialogConfig.autoFocus = true;
    dialogConfig.data = dialogData;
    dialogConfig.minWidth = minWidth;

    return this.dialog.open(DialogMessageComponent, dialogConfig).afterClosed();
  }

  showYesNo(message: string, title: string = null, minWidth: number = 400): Observable<any> {
    const dialogConfig = new MatDialogConfig();

    var dialogData : IDialogMessage = {
      title : title,
      message : message
    } 

    //Configuracion del dialog
    dialogConfig.autoFocus = true;
    dialogConfig.data = dialogData;
    dialogConfig.minWidth = minWidth;

    return this.dialog.open(DialogYesNoComponent, dialogConfig).afterClosed();
  }

}