import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, catchError, firstValueFrom } from 'rxjs';
import { L10N_LOCALE, L10nLocale } from 'angular-l10n';
import { APIResponse } from '../interfaces/response.interface';
import { IApiUserSession } from '../interfaces/user.api.interface';
@Injectable({ providedIn: 'root' })
export class SessionApiService {

  private apiUrl = `/api/session`;

  // Constructor
  constructor(private httpClient: HttpClient, @Inject(L10N_LOCALE) public locale: L10nLocale) { }


  /**
   * Realiza una petición de solicitud para el cambio de contraseña
   * @param email Email del usuario
   * @returns Retorna la respuesta del servidor
   */
  RequestRecoveryPassword(email: string): Observable<APIResponse<boolean>> {
    return this.httpClient.post<APIResponse<boolean>>(`${this.apiUrl}/passwordRecovery`, {email: email}).pipe();
  }


  /**
   * Realiza una petición para el cambio de contraseña
   * @param idUser Identificador del usuario
   * @param password1 Contraseña
   * @param password2  Contraseña de verificacion
   * @param password3 Contraseña original
   * @returns Retorna la respuesta del servidor
   */
  RequestRecoveryPasswordChange(idUser: number, password1: string, password2: string, password3 : string): Observable<APIResponse<boolean>> {
    return this.httpClient.post<APIResponse<boolean>>(`${this.apiUrl}/passwordRecoveryChange`, {idUser, password1, password2, password3}).pipe();
  }

  /**
   * Realiza una petición para actualizar los permisos del usuario
   * @param id Identificador del usuario
   * @returns Retorna la respuesta del servidor
   */
  public async RequestUserPermissions(id: number): Promise<{ module: number; permissions: number[]}[]> {
    return await firstValueFrom(this.httpClient.post<{module: number, permissions: number[]}[]>(`${this.apiUrl}/getUserPermissions`, { idUser: id }));
  }

  /**
   * Obtiene la URI de inicio de session en google AD
   */
  public RequestGoogleAuthUri(): Observable<APIResponse<string>> {
    return this.httpClient.get<APIResponse<string>>(`${this.apiUrl}/googleSignIn`).pipe();
  }

  /**
   * Inicia sessión a partir del código de autentificacion de google
   */
  public SignInGoogle(code: string): Observable<APIResponse<IApiUserSession>> {
    return this.httpClient.post<APIResponse<IApiUserSession>>(`${this.apiUrl}/googleSignIn`, {code}).pipe();
  }
}
