import { IApiEntity } from "./entity.api.interface";

export interface IApiPermission extends IApiEntity {
}


/**
 * Listado modulos
 */
export enum Modules{
    Users = 10, // Usuarios
    Clients = 20, // Clientes
    Suppliers = 30, // Proveedores
    Locations = 40, // Localizaciones
    Countries = 50, // Paises
    Regions = 60, // Provincias
    Cities = 80, // Localidades
    SettingsValues = 90, // Configuración de valores
    Roles = 100, // Roles
    Reception = 110, // Recepciones
    LocationsZones = 120, // Almacenes/Zonas
    LocationsZonesAreas = 130, // Almacenes/Zonas/Áreas
    ClientsCenters = 140, // Clientes/Centros
    RolesPermissions = 150, // Roles/Permisos
    SuppliersCenters = 160, // Proveedores/Centros
    UsersLocations = 170, // Usuarios/Localizaciones
    UsersRoles = 180, // Usuarios/Roles
    ReceptionBatchesDocumentsGroups = 190, // Recepciones/Grupos de documentos
    ReceptionBatchesDocumentsGroupsDocuments = 200, // Recepciones/Grupos de documentos/Documentos
    ReceptionBatchesDocumentsGroupsComments = 210, // Recepciones/Grupos de documentos/Comentarios
    Products = 220, // Productos
    LocationsProducts = 230, // Productos/Localizaciones
    ClientsProducts = 240, // Productos/Clientes
    ProductionClassification = 250, // Clasificación
    ProductionProduction = 260, // Producción
    ProductionBatches = 270, // Lotes de producción
    ProductionBatchesStatus = 280, // Lotes de producción/Historico de estados
    ProductionBatchesInputsPackagings = 290, // Lotes de producción/Entrada envases
    ProductionBatchesInputsBatches = 300, // Lotes de producción/Entrada lotes
    ProductionBatchesPackagings = 310, // Lotes de producción/Envases
    LocationsClassificationZones = 320, // Localizaciones/Zonas de clasificación
    ClientsProductsCodes = 330, //Clientes/Códigos de productos
    Loads = 340, // Cargas
    LoadsStatus = 350, // Cargas/Historico de estados
    LoadsPackagings = 360, // Cargas/Envases
    LoadsDocuments = 370, //Cargas/Documentos
    LoadsComments = 380, // Caragas/Comentarios
    StatisticsProducts = 390, // Estadísticas de productos
    StatisticsGeoZones = 400, // Estadísticas de GeoZonas
    StatisticsStoragesEntry = 410, // Estadísticas de entradas de almacén
    Geoloc = 420, // Geolocalización
  }
  
  /**
  * Listado permisos
  */
  export enum Permissions{
    List = 10, // Consulta
    Add = 20, // Alta
    Edit = 30, // Edición
    PrintLabel = 40, // Imprimir etiqueta
    ListStorages = 50, // Consulta de almacenes
    StatusHistory = 60, //Histórico de estados
    Delete = 70, //Borrado
    ReleaseLocation = 80, //Liberar plaza
    AssignLocation = 90, //Asignar plaza
    Changestatus = 100, //Cambiar estado
    Download = 110, //Descargar
    CancelPackaging = 120, //Anular envase
  }
  