import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoggedInGuard } from 'src/app/shared/logged-in.guard';
import { MainLayoutComponent } from './shared/_layout/main-layout/main-layout.component';
import { PrintLayoutComponent } from './shared/_layout/print-layout/print-layout.component';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { IndexComponent } from './index/index.component';


const routes: Routes = [  
  { path: '', redirectTo: '/index', pathMatch: 'full' },

  {
    path: 'index',
    component: MainLayoutComponent,
    children: [
      { path: '', component: IndexComponent, canActivate: [LoggedInGuard] },
    ],
    canActivate: [LoggedInGuard],
    canActivateChild: [LoggedInGuard]
  },
  {
    path: 'forbidden',
    component: MainLayoutComponent,
    children: [
      { path: '', component: ForbiddenComponent, canActivate: [LoggedInGuard] },
    ],
    canActivate: [LoggedInGuard],
    canActivateChild: [LoggedInGuard]
  },
  
  { path: 'users', component: MainLayoutComponent, canActivate: [LoggedInGuard], loadChildren: () => import('./users/users.module').then((m) => m.UsersModule) },
  { path: 'settingValues', component: MainLayoutComponent, canActivate: [LoggedInGuard], loadChildren: () => import('./settingValues/setting-values.module').then((m) => m.SettingValuesModule)},
  { path: 'countries', component: MainLayoutComponent, canActivate: [LoggedInGuard], loadChildren: () => import('./countries/countries.module').then((m) => m.CountriesModule) },
  { path: 'regions', component: MainLayoutComponent, canActivate: [LoggedInGuard], loadChildren: () => import('./regions/regions.module').then((m) => m.RegionsModule) },
  { path: 'cities', component: MainLayoutComponent, canActivate: [LoggedInGuard], loadChildren: () => import('./cities/cities.module').then((m) => m.CitiesModule) },
  { path: 'roles', component: MainLayoutComponent, canActivate: [LoggedInGuard], loadChildren: () => import('./roles/roles.module').then((m) => m.RolesModule) },
  { path: 'locations', component: MainLayoutComponent, canActivate: [LoggedInGuard], loadChildren: () => import('./locations/locations.module').then((m) => m.LocationsModule) },
  { path: 'clients', component: MainLayoutComponent, canActivate: [LoggedInGuard], loadChildren: () => import('./clients/clients.module').then((m) => m.ClientsModule)},
  { path: 'suppliers', component: MainLayoutComponent, canActivate: [LoggedInGuard], loadChildren: () => import('./suppliers/suppliers.module').then((m) => m.SuppliersModule) },
  { path: 'receptionBatches', component: MainLayoutComponent, canActivate: [LoggedInGuard], loadChildren: () => import('./receptionBatches/reception-batches.module').then((m) => m.ReceptionBatchesModule)},
  { path: 'account', loadChildren: () => import('./account/account.module').then((m) => m.AccountModule) },
  { path: 'print', component: PrintLayoutComponent, canActivate: [LoggedInGuard], loadChildren: () => import('./print/print.module').then((m) => m.PrintModule) },
  { path: 'products', component: MainLayoutComponent, canActivate: [LoggedInGuard], loadChildren: () => import('./products/products.module').then((m) => m.ProductsModule) },
  { path: 'productionBatches', component: MainLayoutComponent, canActivate: [LoggedInGuard], loadChildren: () => import('./productionBatches/productionBatches.module').then((m) => m.ProductionBatchesModule) },
  { path: 'loads', component: MainLayoutComponent, canActivate: [LoggedInGuard], loadChildren: () => import('./loads/loads.module').then((m) => m.LoadsModule) },
  { path: 'statistics', component: MainLayoutComponent, canActivate: [LoggedInGuard], loadChildren: () => import('./statistics/statistics.module').then((m) => m.StatisticsModule) },
  { path: 'geoloc', component: MainLayoutComponent, canActivate: [LoggedInGuard], loadChildren: () => import('./geoloc/geoloc.module').then((m) => m.GeolocModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
