<app-spinner *ngIf="this.loading.getLoading()"></app-spinner>
<app-header class="flex-grow-0"></app-header> 
<div class="container-general w-100">
    <div class="container-menu scroll-container">
        <app-menu class="flex-grow-0"></app-menu>
    </div>
    <div class="container-main">
        <div class="w-100 p-4 mt-4">
            <router-outlet class="flex-grow-0"></router-outlet>
        </div>
    </div>
</div>
