<aside class="scroll-container">
    <div class="offcanvas-lg offcanvas-start scroll-container" tabindex="-1" id="mainMenu" aria-labelledby="mainMenuOffcanvasLabel" aria-modal="true" role="dialog">
        <div class="offcanvas-header border-bottom">
            <h5 class="offcanvas-title" id="mainMenuOffcanvasLabel">Menú</h5>
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Cerrar menu" data-bs-target="#mainMenu"></button>
        </div>
        <div class="offcavas-body">
            <nav class="w-100">
                <ul class="main-menu list-unstyled mt-4 mb-0 pb-3">
                    <li *ngFor="let menuItem of menuOptions" [ngClass]="{'dropdown': hasChildItems(menuItem)}" (click)="handleMenuClick($event, menuItem)" routerLinkActive="active">
                        <a class="d-block py-4 text-center" [routerLink]="menuItem.routerLink" routerLinkActive="active">
                            <span class="d-inline-block w-100 text-center pb-1"><img class="{{menuItem.iconStyle}}" src="../../../assets/img/icons/{{menuItem.icon}}" alt="" /></span>
                            <span class="d-inline-block w-100 text-center" translate>$menu.{{ menuItem.name }}</span>
                            <div class="SubLinks d-none" *ngIf="hasChildItems(menuItem)" routerLinkActive="active">
                                <a *ngFor="let childItem of menuItem.childItems" [routerLink]="childItem.routerLink" routerLinkActive="active" (click)="handleSubMenuClick($event, childItem)" type="button" translate>
                                    $menu.{{ childItem.name }}
                                </a>
                            </div>
                        </a>
                    </li>
                    <li (click)="logout()">
                        <a class="d-block py-4 text-center" href="#" title="Salir">
                            <span class="d-inline-block w-100 text-center pb-1"><img class="menu-icon" src="../../../assets/img/icons/icon_exit.png" alt="" /></span>
                            <span class="d-inline-block w-100 text-center" translate>$menu.logout</span>
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</aside>
<div class="sub-menu scroll-container" *ngIf="menuSubItemsOptions.length > 0" @fadeInOut>
    <section>
        <ul class="list-unstyled d-block">
            <li *ngFor="let submenuItem of menuSubItemsOptions">                
                <ng-container 
                    [ngTemplateOutlet]="hasChildItems(submenuItem) ? menuItemDropdown : menuItemLink" 
                    [ngTemplateOutletContext]="{submenuItem:submenuItem}">
                </ng-container>
            </li>
        </ul>
    </section>
</div>

<ng-template #menuItemDropdown let-submenuItem='submenuItem'>
    <div class="dropdownMenuLink py-4" routerLinkActive="active">
        <a class="d-block text-start dropdownMenuLinkTopLevel" routerLinkActive="active" translate=""><i class="fa-solid fa-angle-down"></i> $menu.{{ submenuItem.name }}</a>
        <ul class="list-unstyled d-block mt-2 third-level-menu">
            <li class="position-relative mb-2 ms-4" *ngFor="let childItem of submenuItem.childItems" routerLinkActive="active">
                <a [routerLink]="childItem.routerLink" routerLinkActive="active" (click)="handleSubMenuClick($event, submenuItem)" type="button" translate>
                    $menu.{{ childItem.name }}
                </a>
            </li>
        </ul>
    </div>    
</ng-template>

<ng-template #menuItemLink let-submenuItem='submenuItem'>
    <a class="d-block py-4 text-start" [routerLink]="submenuItem.routerLink" (click)="handleSubMenuClick($event, submenuItem)" routerLinkActive="active" translate><i class="fa-solid fa-angle-right"></i> $menu.{{ submenuItem.name }}</a>
</ng-template>

