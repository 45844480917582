import { Injectable } from "@angular/core";
import { IApiUser, IApiUserSession, UserRole } from "../interfaces/user.api.interface";
import { Store } from "@ngrx/store";
import * as SessionActions from './session.actions';
import * as SessionSelectors from './session.selectors';
import { IApiLoginCredentials, IApiSession, ILoginError } from "../interfaces/session.api.interface";
import { Observable, Subject, map, shareReplay } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { SessionApiService } from "../services/session.api.service";

@Injectable({ providedIn: 'root' })
export class SessionService {

    //Usuario de sessión
    private currentUser : IApiUserSession;

    private logoutSource = new Subject<void>();

    constructor(private store: Store<IApiUser>, private dialogRef: MatDialog, private sessionApiService: SessionApiService) {
      this.loadSession();

      this.sessionUser$().subscribe(user => {
          this.currentUser = user;
        });          
    }

    private loadSession(): void {
        try {
          if (typeof localStorage !== 'undefined' && localStorage != null && localStorage.getItem('session') != null){
            const session = JSON.parse(atob(localStorage.getItem('session'))) as IApiSession;
            this.store.dispatch(SessionActions.loginSuccess({ session }));
          }
        } catch (err) {
          console.error(err);
          var error = {error : true, errorMessage : null} as ILoginError;
          this.store.dispatch(SessionActions.loginFailure({error}));
        }
      }

    logout(): void {
      this.dialogRef.closeAll();
      this.store.dispatch(SessionActions.logout());
      this.logoutSource.next();
    }

    private sessionUser$(): Observable<IApiUserSession> {
        return this.store.select(SessionSelectors.getSessionUser).pipe(shareReplay());
    }

    sessionUserChanges$(): Observable<IApiUserSession> {
      return this.store.select(SessionSelectors.getSessionUser);
    }

    sessionLoginErrorChanges$(): Observable<ILoginError> {
      return this.store.select(SessionSelectors.getSessionError);
    }

    async googleSignIn(session : IApiUserSession){
      if (session != null){
        this.store.dispatch(SessionActions.loginSuccess({ session }))
      }
    }
    
    /*
    *    Getter User
    */
    get user(): IApiUserSession {
        return this.currentUser;
    }
    
    /**
     * Comprueba si el usuario está conectado
     * @returns true if logged
     */
    isLoggedIn(): boolean {
        return !!this.currentUser && !this.IsSessionExpired();
    }

    /**
     * Comprueba si es administrador
     */
    isAdmin(): boolean {
      return this.currentUser?.IsAdmin;
    }

    /**
     * Disparador de la accion Login
     * @param credentials Datos de las credenciales
     */
    login(credentials: IApiLoginCredentials): void {
      this.store.dispatch(SessionActions.login({ credentials }));
    }

    getInitials() : string {
      var ret = "";

      if (this.currentUser.name != null && this.currentUser.name.length > 0){
        ret = ret + this.currentUser.name.substring(0,1).toUpperCase();
      }

      if (this.currentUser.lastname1 != null && this.currentUser.lastname1.length > 0){
        ret = ret + this.currentUser.lastname1.substring(0,1).toUpperCase();
      }
      return ret;
    }

    /**
     * Comprueba si el token de acceso está expirado     
     */
    private IsSessionExpired() : boolean {  
        if (this.currentUser == null || this.currentUser.accessTokenExpiration == null) return true;
        return this.currentUser.accessTokenExpiration < Math.floor(Date.now() / 1000);
    }

    /**
     * Recarga los permisos del usuario
     */
    async LoadPermissions() {
      const idUser = this.currentUser.idUser
      let permissions = await this.sessionApiService.RequestUserPermissions(idUser);
      this.store.dispatch(SessionActions.storeUserPermissions({ permissions }));
      if (permissions){
        this.currentUser = {...this.currentUser, ListPermissions: permissions};
      }
    }

}