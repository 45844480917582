import { animate, group, state, style, transition, trigger } from '@angular/animations';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { L10N_LOCALE, L10nLocale, L10nTranslationService } from 'angular-l10n';
import { IMenuItem, MenuConfig } from 'src/utilities/menu/menu';
import { SessionService } from 'src/utilities/session/session.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  animations: [
    trigger("fadeInOut", [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('1s ease-in',  style({ opacity: '0' })),
      ]),
      transition(':leave', [
        animate('1s ease-in', style({ opacity: '0' })),
      ]),
    ])
  ]
})
export class MenuComponent implements OnInit {

  private menuConfig: MenuConfig

  private submenuItems: IMenuItem[];

  animationState = true;

  constructor(private router: Router, public sessionService: SessionService, @Inject(L10N_LOCALE) public locale: L10nLocale, private translation: L10nTranslationService) { 
    this.menuConfig = new MenuConfig();
    this.submenuItems = [];
  }


  private wasInside = false;

  @HostListener('click')
  clickInside() {   
    this.wasInside = true;
  }

  @HostListener('document:click')
  clickout() {
    if (!this.wasInside) {
      this.submenuItems = []; 
    }
    this.wasInside = false;
  }

  ngOnInit(): void {
  }

  get menuOptions(): IMenuItem[] {
    let options = this.menuConfig.GetMenuOptions(this.sessionService);
  
    return options;
  }

  get menuSubItemsOptions(): IMenuItem[] {  
    return this.submenuItems;
  }


  public hasChildItems(menuItem: IMenuItem)
  {
    return menuItem.childItems != null && menuItem.childItems.length > 0
  }

  public handleMenuClick(event: MouseEvent, menuItem : IMenuItem){
    //Reset submenu
    this.submenuItems = [];
    if (menuItem.childItems != null && menuItem.childItems.length > 0){
      this.submenuItems =  this.menuConfig.GetSubMenuOptions(this.sessionService, menuItem);
      this.animationState = this.animationState === true ? false : true;
    }
  }

  public handleSubMenuClick(event: MouseEvent, menuItem : IMenuItem){
    //Reset submenu
    this.submenuItems = [];    
  }

  logout(): void {
    this.sessionService.logout();
    this.router.navigate(['/account/login']);
  }

}
