import { createAction, props } from '@ngrx/store';
import { IApiUser, IApiUserSession } from '../interfaces/user.api.interface';
import {IApiSession, IApiLoginCredentials, ILoginError} from '../interfaces/session.api.interface'
export const loadSession = createAction('[Session] Load Session');


/**
 * Acción Guardado de sessión
 */
export const storeSession = createAction(
  '[Session] Store Session',
  props<{ session: IApiUserSession | null }>()
);

/**
 * Acción Guardado de sessión correcto
 */
export const storeSessionSuccess = createAction('[Session] Store Session Success');

/**
 * Acción Guardado de sessión erroneo
 */
export const storeSessionFailure = createAction(
  '[Session] Store Session Failure',
  props<{ error: any }>()
);





/**
 * Acción Login de un usuario
 */
export const login = createAction(
  '[Session] Login',
  props<{ credentials: IApiLoginCredentials }>()
);

/**
 * Acción Login correcto de un usuario
 */
export const loginSuccess = createAction(
  '[Session] Login Success',
  props<{ session: IApiUserSession }>()
);

/**
 * Acción Login incorrecto de un usuario
 */
export const loginFailure = createAction('[Session] Login Failure', props<{ error: any }>());


/**
 * Acción Logout de un usuario
 */
export const logout = createAction('[Session] Logout');

/**
 * Acción Logout correcto de un usuario
 */
export const logoutSuccess = createAction('[Session] Logout Success');

/**
 * Acción Logout incorrecto de un usuario
 */
export const logoutFailure = createAction('[Session] Logout Failure', props<{ error: ILoginError }>());

/**
 * Acción Guardado de permisos
 */
export const storeUserPermissions = createAction(
  '[Session] Store User Permissions',
  props<{ permissions: { module: number; permissions: number[]}[] }>()
);
