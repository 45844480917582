import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: 'input[type="text"].form-control.text-box.single-line, ng-autocomplete input[type="text"]'
})
export class UppercaseDirective {

  constructor(private el: ElementRef, private control: NgControl) {
  }

  @HostListener('input', ['$event']) onInput(event: Event) {
    const input = event.target as HTMLInputElement;
    const transformedValue = input.value.toUpperCase();
    this.control.control?.setValue(transformedValue, { emitEvent: false }); // Sin emitir un nuevo evento para evitar ciclos infinitos
  }

  @HostListener('blur', ['$event']) onBlur(event: Event) {
    const input = event.target as HTMLInputElement;
    const transformedValue = input.value.toUpperCase();
    this.control.control?.setValue(transformedValue, { emitEvent: false }); // Igualmente aquí
  }
}