import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from "@ngrx/store";
import { SessionState } from "./session.reducer";
import { SessionService } from "./session.service";
import * as SessionActions from './session.actions';
import { environment } from '../../environments/environment';
import { of } from 'rxjs';
import {
  catchError,
  map,
  concatMap,
  tap,
  map as rxMap,
  withLatestFrom,
  filter,
} from 'rxjs/operators';
import { IApiUserSession } from "../interfaces/user.api.interface";

@Injectable()
export class SessionEffects{

    constructor(
        private actions$: Actions,
        private http: HttpClient,
        private router: Router,
        private state: Store<{ session: SessionState }>,
        private session: SessionService
      ) {
      }

      storeSession$ = createEffect(() => {
        return this.actions$.pipe(
          ofType(SessionActions.storeSession),
          concatMap(({ session }) => {
            try {
              if (session) {
                localStorage.setItem('session', btoa(JSON.stringify(session)));
              } else {
                localStorage.clear();
              }
              return of(SessionActions.storeSessionSuccess());
            } catch (error) {
              console.error(error);
              return of(SessionActions.storeSessionFailure({ error }));
            }
          })
        );
      });

    login$ = createEffect(() => {      
        return this.actions$.pipe(
          ofType(SessionActions.login),
          concatMap(({ credentials }) => 
            this.http.post<IApiUserSession>(`${environment.api}/session/signIn`, credentials).pipe(
              map(session => 
                SessionActions.loginSuccess({ session })),                            
              catchError(error => 
                of(SessionActions.loginFailure({ error })))
            )
          )
        );
      });

      
//{error: true, errorMessage : null} as ILoginError
      loginSuccess$ = createEffect(() => {
        return this.actions$.pipe(
          ofType(SessionActions.loginSuccess),
          tap( res => 
            console.log('login success:', res)),
          map(({ session }) => {
            return SessionActions.storeSession({ session });
          })
        );
      });


      loginFailures$ = createEffect(() => {
        return this.actions$.pipe(
          ofType(SessionActions.loginFailure),
          tap( res => 
            console.log('login fail:', res)),
          map(({ error }) => {
            return SessionActions.storeSession({ session:null });
          })
        );
      });  


      logout$ = createEffect(() => {
        return this.actions$.pipe(
          ofType(SessionActions.logout),
          concatMap(() => 
            this.http.delete(`${environment.api}/session`).pipe(
              map(session => 
                SessionActions.logoutSuccess()),                            
              catchError(error => 
                of(SessionActions.logoutFailure(error)))
            ))       
        );
      });
    
      logoutSuccess$ = createEffect(() => {
        return this.actions$.pipe(
          ofType(SessionActions.logoutSuccess),
          map(() => SessionActions.storeSession({ session: null })),
          tap((data) => { this.router.navigate(['/account/login']); })
        );
      });

      logoutFailure$ = createEffect(() => {
        return this.actions$.pipe(
          ofType(SessionActions.logoutFailure),
          map(() => SessionActions.storeSession({ session: null })),
          tap((data) => { this.router.navigate(['/account/login']); }),
          tap((err) => console.log(err)) 
        );
      });

      storeUserPermissions$ = createEffect(() => {      
        return this.actions$.pipe(
          ofType(SessionActions.storeUserPermissions),
          map(({ permissions }) => 
            {
              //Actualizamos los permisos
              const session = JSON.parse(atob(localStorage.getItem('session'))) as IApiUserSession;
              session.ListPermissions = permissions;
              return SessionActions.storeSession({ session }); 
            }
          )
        );
      });
      
}