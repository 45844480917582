<div class="page-header bd-navbar fixed-top bg-primary header-int pt-3 border-bottom border-white border-5">
    <div class="row">
        <div class="col-8">
            <div class="d-inline d-lg-none">
                <button class="navbar-toggler p-2" type="button" data-bs-toggle="offcanvas" data-bs-target="#mainMenu" aria-controls="mainMenu" aria-label=""><i class="fa-solid fa-bars text-white"></i></button>
            </div>
            <span class="ps-1 p-sm-1 ps-md-4 ms-md-2 pt-3"><a [routerLink]="['/account/index']"><img class="logo-main-header" src="../../../assets/img/logo_pictrack.png" alt="{{'$general.fullProjectName' | translateAsync}}"/></a></span>
            <img class="logo-secondary-header d-sm-inline d-none" src="../../../assets/img/logo_veolia_int.png" alt="Veolia"/>
        </div>
        <div class="col-4 text-end"><div class="mt-2 me-2"><span class="border rounded-circle p-2 bg-white border-white text-primary fw-bold">{{sessionService.getInitials()}}</span></div></div>
    </div>
  </div>


  